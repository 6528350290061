import React from "react";
import styled from "styled-components/macro";
import { Link } from "gatsby";
import { lgBtnClick } from "../../common/firebaseAnalytics";

const NewsPost = ({
  postId,
  businessName,
  title,
  description,
  postImage,
  timestamp,
}) => {
  const parsePostImg = (image) => {
    if (image.includes("images.ctfassets.net")) return image;
    return "data:image/*;base64," + image;
  };

  return (
    <Link
      onClick={() => lgBtnClick("feed_post_open", { postId: postId })}
      to={`/post?${postId}`}
      style={{ textDecoration: "none", color: "black" }}>
      <Post key={postId}>
        <PostHead>
          <Image src={parsePostImg(postImage)}></Image>
        </PostHead>
        <PostBody>
          <Title>
            {businessName} | {title}
          </Title>
          <PostTimestamp>{timestamp || ""}</PostTimestamp>
        </PostBody>
      </Post>
    </Link>
  );
};

export default NewsPost;

const Post = styled.div`
  width: 100%;
`;

const PostHead = styled.div`
  width: 100%;
  height: 157px;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    height: 130px;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
    max-height: 350px;
    border-radius: 10px 10px 0 0;
  }
`;

const PostBody = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
  }
`;

const Title = styled.h3`
  font-size: 1.8rem;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 1.6rem;
  }
`;

const PostTimestamp = styled.div`
  font-size: 1.2rem;
`;
