import React from "react";
import styled from "styled-components/macro";
import { IoIosArrowForward, IoIosStarOutline } from "react-icons/io";
import { Link } from "gatsby";
import { lgBtnClick } from "../common/firebaseAnalytics";

const FeaturePost = ({
  postId,
  businessName,
  title,
  description,
  postImage,
  timestamp,
}) => {
  const parsePostImg = (image) => {
    if (image.includes("images.ctfassets.net")) return image;
    return "data:image/*;base64," + image;
  };

  return (
    <Link
      onClick={() => lgBtnClick("feed_post_feature_open", { postId: postId })}
      to={`/post?${postId}`}
      style={{ textDecoration: "none", color: "black" }}>
      <Post key={postId}>
        <PostHead>
          <Image src={parsePostImg(postImage)}></Image>
        </PostHead>
        <PostBody>
          <Title>
            {businessName} | {title}
          </Title>
          <Description>{description}</Description>
          <Tag>
            Feature <IoIosStarOutline />
          </Tag>
          <Timestamp>{timestamp || ""}</Timestamp>
          <ReadMore>
            Read More <IoIosArrowForward />
          </ReadMore>
        </PostBody>
      </Post>
    </Link>
  );
};

export default FeaturePost;

const Post = styled.div`
  width: 100%;
`;

const PostHead = styled.div`
  width: 100%;
  height: 304px;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    height: 130px;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
    max-height: 350px;
    border-radius: 10px 10px 0 0;
  }
`;

const PostBody = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    flex-basis: 100%;
    padding: 0px;
  }
`;

const Title = styled.h3`
  font-size: 2rem;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 1.6rem;
  }
`;

const Description = styled.p`
  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    display: none;
  }
`;

const Timestamp = styled.div`
  font-size: 1.2rem;
`;

const ReadMore = styled.button`
  outline: none;
  background-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid black;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.4rem;
  float: right;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    display: none;
  }
`;

const Tag = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4rem;
`;
