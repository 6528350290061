import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, LoaderWrapper } from "../common/styled-components";
import Layout from "../components/Layout";
import NewsPost from "../components/NewsPost";
import axios from "axios";
import Button from "../components/Button";
import moment from "moment";
import FeaturePost from "../components/FeaturePost";
import LoadingSpinner from "../components/LoadingSpinner";
import { Link, graphql } from "gatsby";
import { IoIosArrowForward } from "react-icons/io";
import { LinkHeader } from "../components/LinkHeader";
import { lgBtnClick } from "../common/firebaseAnalytics";
import get from "lodash/get";

const NewsFeed = ({ data }) => {
  const [latestItems, setlatestItems] = useState([]);
  const [olderItems, setOlderItems] = useState([]);
  const [featurePost, setFeaturePost] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const INTRO_HTML = get(
    data,
    "allContentfulIndexPage.nodes[0].intro.childMarkdownRemark.html"
  );

  const fetchPageOfPosts = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `/.netlify/functions/newsPostEntries?page=${currPage}`
      );

      let olderList = olderItems.concat(result.data.results);

      if (latestItems.length === 0) {
        let latestList = latestItems;
        latestList = olderList.splice(0, 5);
        setlatestItems(latestList);
      }

      setTotal(result.data.total);
      setOlderItems(olderList);
      setCurrPage(currPage + 1);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLatestFeature = async () => {
    try {
      const result = await axios.get(
        `/.netlify/functions/newsPostEntries?page=1&featured=true`
      );

      setFeaturePost(result.data.results[0]);
    } catch (err) {
      console.log(err);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPageOfPosts();
    fetchLatestFeature();
  }, []);

  return (
    <Layout>
      <LinkHeader
        text={
          "Click here to support your favourite places by helping us add them! ❤"
        }
        url={"https://forms.gle/jkiqSfEUaXXutcFm9"}
      />
      <Container>
        <Intro
          dangerouslySetInnerHTML={{
            __html: INTRO_HTML,
          }}
        />
        {!featurePost && (
          <LoaderWrapper>
            <LoadingSpinner />
          </LoaderWrapper>
        )}
        {featurePost && (
          <>
            <ListTitle>Latest</ListTitle>
            <FeatureArea>
              <FeaturePostWrapper>
                <FeaturePost
                  postId={featurePost.sys.id}
                  businessName={featurePost.businessName}
                  title={featurePost.title}
                  timestamp={moment(featurePost.sys.createdAt).format(
                    "DD MMM YYYY"
                  )}
                  description={featurePost.description}
                  postImage={featurePost.postImage.fields.file.url}
                  areasOfAvailability={featurePost.areasOfAvailability}
                  externalPostLinkUrl={featurePost.externalPostLink}
                  businessId={featurePost.businessId}></FeaturePost>
              </FeaturePostWrapper>
              {latestItems.map((post, index) => {
                return (
                  <NewsPost
                    key={index}
                    postId={post.sys.id}
                    businessName={post.businessName}
                    title={post.title}
                    timestamp={moment(post.sys.createdAt).format("DD MMM YYYY")}
                    description={post.description}
                    postImage={post.postImage.fields.file.url}
                    areasOfAvailability={post.areasOfAvailability}
                    externalPostLinkUrl={post.externalPostLink}
                    businessId={post.businessId}></NewsPost>
                );
              })}
            </FeatureArea>
            <Banner>
              <BannerTitle>
                Checkout the&nbsp;
                <Link
                  to="/directory"
                  onClick={() =>
                    lgBtnClick("feed_banner", { dest: "directory" })
                  }>
                  {" "}
                  Directory
                </Link>{" "}
                <IoIosArrowForward />
              </BannerTitle>
              <BannerBody>
                For our full list of everyone still open and serving.
              </BannerBody>
            </Banner>
            <ListTitle>Older Posts</ListTitle>
            {olderItems.length ? (
              <PostList>
                {olderItems.map((post, index) => {
                  return (
                    <NewsPost
                      key={index}
                      postId={post.sys.id}
                      businessName={post.businessName}
                      title={post.title}
                      timestamp={moment(post.sys.createdAt).format(
                        "DD MMM YYYY"
                      )}
                      description={post.description}
                      postImage={post.postImage.fields.file.url}
                      areasOfAvailability={post.areasOfAvailability}
                      externalPostLinkUrl={post.externalPostLink}
                      businessId={post.businessId}></NewsPost>
                  );
                })}
              </PostList>
            ) : null}
            {isLoading && (
              <LoaderWrapper>
                <LoadingSpinner />
              </LoaderWrapper>
            )}
            {total > latestItems.length + olderItems.length && (
              <LoadMoreWrapper>
                <LoadMoreButton
                  onClickHandler={fetchPageOfPosts}
                  text={"Load more posts"}
                />
              </LoadMoreWrapper>
            )}
          </>
        )}
      </Container>
    </Layout>
  );
};

export default NewsFeed;

export const pageQuery = graphql`
  {
    allContentfulIndexPage {
      nodes {
        intro {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

const Intro = styled.h1`
  margin-top: 50px;
  margin-bottom: 50px;
  letter-spacing: 0.05rem;
  font-weight: normal;
  white-space: pre-line;
  text-align: center;

  & p {
    font-size: 2.1rem;
    line-height: 2.8rem;
  }

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    white-space: normal;

    & p {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }
`;

const FeatureArea = styled.section`
  flex-basis: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
`;

const FeaturePostWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
`;

const ListTitle = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 0.5px solid #bebebe;
  margin-top: 20px;
`;

const PostList = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  margin-bottom: 20px;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const LoadMoreButton = styled(Button)``;

const Banner = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: black;
  color: white;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const BannerTitle = styled.h2`
  font-size: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    font-weight: bold;
    color: white;
  }
`;

const BannerBody = styled.p`
  font-size: 1.8rem;

  @media only screen and (min-device-width: 320px) and (max-width: 500px) {
    font-size: 1.6rem;
  }
`;
