import React from "react";
import styled from "styled-components/macro";
import { OutboundLink } from "gatsby-plugin-google-analytics";

export const LinkHeader = ({ text, url }) => (
  <Header>
    <Connect>
      <LinkToForm href={url} target="_blank">
        {text}
      </LinkToForm>
    </Connect>
  </Header>
);

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
`;

const Connect = styled.div`
  width: 100%;
  background-color: #0f52ba;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  white-space: pre-line;
`;

const LinkToForm = styled(OutboundLink)`
  font-weight: bold;
  letter-spacing: 0.2px;

  &:link {
    color: white;
  }

  &:visited {
    color: white;
  }
`;
