import React from "react";
import spinnerSvg from "./loading-spinner.svg";
import styled from "styled-components/macro";

const LoadingSpinner = () => {
  return <SpinnerGraphic src={spinnerSvg}></SpinnerGraphic>;
};

export default LoadingSpinner;

const SpinnerGraphic = styled.img`
  height: 100px;
  width: 100px;
`;
