import React from "react";
import styled from "styled-components/macro";

const Button = ({ text, onClickHandler }) => {
  return <StdButton onClick={onClickHandler}>{text}</StdButton>;
};

export default Button;

const StdButton = styled.button`
  outline: none;
  border: 2px solid black;
  background-color: white;
  font-size: 1.6rem;
  text-transform: uppercase;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 5px;
`;
